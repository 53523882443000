.contactImgDiv{
    padding: 10px;
    vertical-align:middle;
    width: 100%;
}

.textContactImg{
    width: 80vw;
    height: 80vw;
    display: block;
    margin:auto;
    object-fit: cover;
    max-width: 500px;
    max-height: 500px;
  }

  @media (max-width: 640px) {
    .contactImgDivFirst{
      display: block !important;
    }
    .contactImgDivSecond{
      display:none !important;
    }
  }


  @media (min-width: 640px) {
    .contactImgDiv{
      width: 50%;
      padding: 30px;
      margin-left: auto;
      margin-right: auto;
    }

    .textContactImg{
        width: 40vw;
        height: 40vw;
      } 


/* 
    .weddingViewImg{
      height:40vw;
      width: 50vw;
      margin: 5px;
    } */
  }