.textAboutDescription{
  font-family: 'Martel', sans-serif;
  font-size: 13px;
  line-height: 26px;
  color: #000000;
}

.textAboutTitle {
  font-family: 'tenderness-regular', sans-serif;
  font-size: 20px;
  line-height: 27px;
  font-weight: 300;
  color: #464646;
}

.aboutMainImg{
  max-height: 23rem;
}

.textPartAbout{
  width: 100%;
  max-width: 700px;
}


.aboutImgDiv{
  width:50%;
  padding: 10px;
  vertical-align:middle;
}

.textAboutImg{
  width: 70vw;
  height: 80vw;
  display: block;
  margin:auto;
  object-fit: cover;
}


.aboutImgDiv{
  width: 100%;
}

@media (max-width: 640px) {
  .aboutImgDivFirst{
    display: block !important;
  }
  .aboutImgDivSecond{
    display:none !important;
  }
}


@media (min-width: 640px) {
  .aboutImgDiv{
    width: 50%;
    padding: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .textAboutImg{
      width: 40vw;
      height: 50vw;
      max-width: 500px;
      max-height: 600px;
    } 
}