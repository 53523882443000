.weddingViewName{
    font-family: 'Derivia Regular', sans-serif;
    font-size: 30px;
    line-height: 40px;
    font-weight: 100;
    color: #464646;
  }
  
  .weddingViewPlace{
    font-family: 'Martel', sans-serif;
    font-size: 12px;
    line-height: 20px;
    font-weight: 100;
    color: #464646;
  }

  .weddingViewImg{
    height:60vw;
    width: 80vw;
    margin: 5px;
    object-fit:cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .weddingViewImgDiv{
    width: 100%;
  }
  @media (max-width: 640px) {
    .weddingViewImgDivFirst{
      display: block !important;
    }
    .weddingViewImgDivSecond{
      display:none !important;
    }
  }

  @media (min-width: 640px) {
    .weddingViewImgDiv{
      width: 50%;
      padding: 30px;
      margin-left: auto;
      margin-right: auto;
    }

    .weddingViewImg{
      height:40vw;
      width: 50vw;
      margin: 5px;
    }
  }