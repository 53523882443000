header{
  z-index: 1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
footer {
  background-color: #F3F5F5;
  width: 100%;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


.bg-mybtn {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 204, 180, var(--tw-bg-opacity));
}

.mainTopImg{
  max-height: 95vh;
}

.menu_icon{
  border-radius: 0.25rem;
  display: inline-flex;
  margin-left: auto;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.backgroundLogo{
  width: 10vw;
  height: auto;
  position: absolute;
  top: 30px;
  left: 50px;
}




.categoriesMainDiv{
  max-width: 1400px;
}


.textTitle{
  padding-bottom: 10px;
  font-family: 'Derivia Regular', sans-serif;
  font-weight:100;
  font-style: normal;
  line-height: 53px;
  font-size: 44px;
  color:#464646 ;
}

.textBasic{
  font-family: 'Martel', sans-serif;
  font-size: 13px;
  line-height: 23px;
  color: #000000;
}

.learnMore{
  font-family: 'Derivia Regular', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #464646;
}


.textHardTitle {
  padding-top: 20px;
  font-family: 'tenderness-regular', sans-serif;
  font-size: 27px;
  line-height: 35px;
  font-weight: 100;
  color: #464646;
}

.categoriesTitle{
  font-family: 'Derivia Regular', sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 100;
  color: #464646;
}

.experienceTitle{
  margin-bottom: 10px;
}

.experiencesName{
  font-family: 'tenderness-regular', sans-serif;
  font-size: 30px;
  line-height: 39px;
  font-weight: 100;
  padding-top: 20px;
  padding-bottom: 15px;
  color: #464646;
}

.experiencesPlace{
  font-family: 'Derivia Regular', sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-weight:100;
  color: #464646;
  padding-bottom: 20px;
}

.textExperience{
  font-family: 'Martel', sans-serif;
  font-size: 14px;
  line-height: 28px;
  font-weight: 300;
  color: #000000;
}



.split-div {
  display: flex;
  border-left: 1px solid lightgray;
}

.line {
  width: 1px;
  background-color: rgb(90, 90, 90);
  height: 100;
}

.reviewImg{
  width: 30vw;
  height: 30vw;
  object-fit:cover;
}

.helloViewImg{
  width: 90%;
  object-fit:contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}




.logoFont{ 
  font-family: "Times", Times, serif;
  font-size: 27px;
  font-weight: 400;
  color: #464646;
}

.mainLogo{
  width: 140px;
  height: auto;
  display: none;
}

@media (min-width: 700px) {

  .mainLogo{
    display: block;
  }
}

@media (min-width: 768px) {

  .spacer{
    height:15px;
  }
  .experienceTitle{
    height: 70px;
  }
  .CategoriesView{
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .helloViewImg{
    width: 80%;
    height: calc(50vw * 0.75);
  }
}

@media (min-width: 992px) {
  .CategoriesView{
    padding-top: calc(40vh - 250px);
    padding-bottom: calc(40vh - 250px);
  }
}


