
  @media (min-width: 640px) {
    /* The dropdown container */
  .dropdown {
    float: left;
    overflow: hidden;
  }
  /* Dropdown content (hidden by default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 130px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown-content-button {
    padding: 12px 16px !important;
    text-align: left;
  }
  }

  /* Add a grey background color to dropdown links on hover */
  .dropdown-content-button:hover {
    background-color: #ddd;
  }
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-content{
    margin-bottom: 10px;
  }

  .navDropdownText{
    padding: 5px 8px;
    line-height: 18px;
    font-size: 13px;
    color: #525252;
    font-family: "Martel";
    float: none;
    color: black;
    display: block;
    width: 100%;
  }

  .navText{
    margin: 5px 10px 5px 10px;
    line-height: 21px;
    font-size: 16px;
    color: #464646;
    font-family: "Martel";
  }
  